.StreetLights {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
}

.streetLightAnimation {
    height: 100%;
    width: 33%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.rightContent {
    width: 60%;
    font-size: 4em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity ease-in 0.5s;
}

.comparison {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;
}

.statistic {
    display: flex;
}

.statisticNumber {
    font-size: 3.5em;
    margin-right: 50px;
}

.statisticUnit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.statisticUnitGraphic {
    fill: #ffffff !important;
}

.statisticUnitText {
    font-size: 1em;
}

.moonContainer {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 125px;
    right: 0;
}

.moonAnimation {
    animation: moonX 20s infinite ease-in;
}

.moonAnimationAfter > svg {
    width: 100px;
    height: 100px;
}

.moonAnimationAfter { 
    overflow: hidden;
    animation: moonY 20s infinite ease-out;
    transform: translateX(150px);
}

@keyframes moonX {
    100% {
      animation-timing-function: ease-in;
      transform: translateX(-50vw);
    }
  }
  
  @keyframes moonY {
    100% {
      animation-timing-function: ease-out;
      transform: translateY(-150px);
    }
  }