.upperTitle {
    font-size: 24px;
    text-transform: uppercase;
}

.mainTitle {
    font-size: 46px;
    text-transform: uppercase;
    width: fit-content;
    border-bottom: 4px solid #7c7c7c;
}