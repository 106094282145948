.EnergySavings {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-around;
}

.energyType {
    height: 100%;
    width: 20%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 4em;

    opacity: 0;
    transition: opacity ease-in 1s;
}

.energyType.show {
    opacity: 1;
}

.energyType > * {
    margin: 10px 0;
}

.energyType > .savings {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow {
    margin-right: 10px;
}

.rotatedArrow {
    transform: rotate(180deg);
}