.BottomBar {
    display: flex;
    justify-content: space-between;

    border-top: solid 4px #7c7c7c;
}

.metaTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    font-size: 1.5em;
}

.metaTextWrapper a {
    color: #ffffff;
}

.universityLogoWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
}

.universityLogo {
    min-width: 350px;
}