@font-face {
  font-family: 'UniversNextW02';
  src:  url('fonts/UniversNextW02.woff2') format('woff2');
}

body {
    margin: 0;
    
    font-family: UniversNextW02;
    font-weight: 500;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
