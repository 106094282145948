.SolarPanels {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
}

.solarPanelAnimation {
  height: 100%;
  width: 45%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.sunContainer {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 125px;
    right: 0;
}

.sunAnimation {
    animation: sunX 20s infinite ease-in;
}

.sunAnimationAfter { 
    overflow: hidden;
    animation: sunY 20s infinite ease-out;
    transform: translateX(150px);
}

.sunAnimationAfter > svg {
    width: 100px;
    height: 100px;
}

@keyframes sunX {
    100% {
        animation-timing-function: ease-in;
        transform: translateX(-50vw);
    }
}
  
@keyframes sunY {
    100% {
        animation-timing-function: ease-out;
        transform: translateY(-100px);
    }
}

.rightContent {
    width: 60%;
    font-size: 4em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity ease-in 0.5s;
}

.comparison {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;
}

.statistic {
    display: flex;
}

.statisticNumber {
    font-size: 3.5em;
    margin-right: 50px;
}

.statisticUnit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.statisticUnitGraphic {
    fill: #ffffff !important;
}

.statisticUnitText {
    font-size: 1em;
}
