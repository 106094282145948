.App {
    display: flex;
    flex-direction: column;

    background-color: #343434;
    padding: 2em;
}

.Title {
    height: 100px;
}

.Content {
    height: calc(100vh - 100px - 75px - 4em);
}

.BottomBar {
    height: 75px;
}