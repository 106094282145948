.EnergyPerStudent {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
}

.legendContainer {
    width: 40%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-size: 3.5em;

    transition: opacity ease-in 0.5s;
}

.legendItem {
    display: flex;
    align-items: center;
}

.legendText {
    width: 80%;
}

.legendAnimation {
    width: 20%;
    display: flex;
    justify-content: center;
}

.barGraphic {
    background-color: #2ca495;

    width: 20%;
    height: 1em;
}

.chartContainer {
    width: 60%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.energyDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 4em;
    max-width: 40%;

    transition: opacity ease-in 0.5s;
}

.energyDataText {
    margin-bottom: 1em;
    width: 85%;
    text-align: center;
}

.energyDataLink {
    font-size: 1.25em;
    border-bottom: 8px solid #7c7c7c;
}