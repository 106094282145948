.YearOverYearSingle {
    width: 100%;
    height: 100%;

    font-size: 4.5em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: opacity ease-in 0.5s;
}

.upperRow, .middleRow, .lowerRow {
    display: flex;
    align-items: center;
}

.upperRow > * {
    padding: 0 0.5em;
}

.middleRow {
    font-size: 2em;
    padding: 0.25em;
}